import {Model} from "../vuemc";

export default class Reset extends Model
{
    defaults() {
        return {
            email:                 null,
            token:                 null,
            password:              null,
            password_confirmation: null,
        }
    }
}
